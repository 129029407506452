.fullscreen-carousel {
  height: 100vh; /* 设置容器高度为屏幕高度 */
  width: 100%; /* 设置容器宽度为100% */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-carousel .carousel {
  height: 100%; /* 设置 Carousel 元素的高度为100% */
}

.fullscreen-carousel .carousel-item {
  height: 100%; /* 设置轮播项的高度为100% */
}

.fullscreen-carousel .carousel-item img {
  height: 100%; /* 设置图片的高度为100% */
  object-fit: cover; /* 让图片按比例填充轮播项 */
}
