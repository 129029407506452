.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  border-bottom: #61dafb 2px solid;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
}
.title_text {
  color: aliceblue;
  padding: 10px;
  border: red 1px solid;
}

.App-link {
  color: #61dafb;
}

.line {
  max-width: 150px;
}

.h4-text {
  margin-top: 20px;
  font-size: 20px;
}

.title-bg {
  min-height: 100vh;
  background-image: url("./commonPic/lavender.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.separator {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #ccc;
}

.separator::before {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
}

.separator::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
}

.full-screen {
  height: 100%;
}

.image-container {
  position: relative;
  display: inline-block;
}

.thumbnail-image {
  cursor: pointer;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: auto;
}
.popup-container img.popup-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.popup-content {
  position: relative;
}

/* .popup-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
} */

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #800aee;
  font-size: 24px;
  cursor: pointer;
}

.square-image {
  width: 100%;
  padding-top: 100%; /* 通过 padding-top 设置容器高度，使其与宽度相等 */
  position: relative;
  overflow: hidden;
}

.square-image .image-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}
